<template>
  <div id="www">
    <button @click="tiaozhuan">跳转</button>
  </div>
</template>
  
  <script>
import wxx from "weixin-jsapi";
export default {
  data() {
    return {};
  },

  methods: {
    tiaozhuan() {
      this.axios.get("https://mall.yaolvxing.com/wx/wx").then((res) => {
        wxx.config({
          debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: "wx0cc5bde0813322ce", // 必填，公众号的唯一标识
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名
          jsApiList: ["onMenuShareAppMessage"], // 必填，需要使用的JS接口列表
        });
        wxx.ready((res) => {
          wxx.onMenuShareAppMessage({
            title:'',
            desc : '',
            link: "https://www.baidu.com/",
            success: function (res) {
              alert(res,"成功")
            },
            fail: function (res) {
              alert(res,"失败")
            },
          });
        });
        wxx.error((res) => {
          console.log(res,'注入失败')
          // console.log("初始化失败",res)
        });
      });
    },
  },
  mounted() {},
};
</script>
  
  
  <style scoped>
</style>